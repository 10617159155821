<template>
    <div class="header" :class="{'open': headerActive===true, 'close': headerActive===false}">
        <div class="menu">
          <img src="../assets/logo.svg" alt="logo" class="logo">
          <img src="../assets/icons/menuClose.svg" alt="closeMenu" class="menuClose" @click="menuOpen">
        </div>
        
        <div class="right">
          <div class="btn sbf" v-for="(block,idx) in blocks" :key="idx">
            {{ block.name }}
            <router-link :to="{path: '/', hash: block.id}" class="pointer"></router-link>
          </div>
        </div>
        <div class="noise"></div>
        <div class="left">
          <router-link :to="{path: '/', hash: 'application'}" class="pointer"></router-link>
          <div class="text sbf">Запись на аудит <router-link :to="{path: '/', hash: 'application'}" class="pointer2"></router-link></div>
          <svg class="icon" width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line y1="1" x2="36" y2="1" stroke-width="2"/>
          <line x1="1" y1="2" x2="1" y2="10" stroke-width="2"/>
          <line x1="35" y1="2" x2="35" y2="10" stroke-width="2"/>
          <line x1="1" y1="34" x2="1" y2="42" stroke-width="2"/>
          <line x1="35" y1="34" x2="35" y2="42" stroke-width="2"/>
          <line y1="43" x2="36" y2="43" stroke-width="2"/>
          <path d="M23.4853 18.0001C23.4853 17.4478 23.0376 17.0001 22.4853 17.0001L13.4853 17.0001C12.933 17.0001 12.4853 17.4478 12.4853 18.0001C12.4853 18.5524 12.933 19.0001 13.4853 19.0001L21.4853 19.0001L21.4853 27.0001C21.4853 27.5524 21.933 28.0001 22.4853 28.0001C23.0376 28.0001 23.4853 27.5524 23.4853 27.0001L23.4853 18.0001ZM14.7071 27.1925L23.1924 18.7072L21.7782 17.293L13.2929 25.7782L14.7071 27.1925Z"/>
          </svg>
          <div class="Links">
            <a href="https://t.me/widenru"><img src="../assets/icons/Telegram.svg" alt="" class="iconLink"></a>
            <a href="https://www.instagram.com/widen_agency?igsh=YTJ6YzF6NW1pdWdy"><img src="../assets/icons/Instagram.svg" alt="" class="iconLink"><img src="../assets/icons/iconStar.svg" class="iconStar"></a>
          </div>
        </div>
        
    </div>
</template>

<style scoped lang="sass">
@media (min-width: 480px) 
  .header
    opacity: 1
    top: 8px
    left: 9px
    z-index: 1000
    background: var(--txtDark)
    display: flex
    position: fixed
    width: calc( 100% - 28px )
    justify-content: space-between
    border: 2px solid var(--milkyBG, #E7E9E8)
    .menu 
      display: none
    .right
      display: flex
      .btn
        position: relative
        z-index: 13
        border-right: 2px solid var(--milkyBG, #E7E9E8)
        display: flex
        padding: 20px 75px
        text-transform: uppercase
        white-space: nowrap
        color: var(--milkyBG)
        flex-direction: column
        justify-content: center
        align-items: center
        animation: btnNotHover 300ms ease-in-out
        .pointer
          position: absolute
          width: 100% 
          height: 100% 
          cursor: pointer
      .btn:hover
        background: var(--accentBlue) url('../assets/noise.png')
        animation: btnHover 300ms ease-in-out
    .left
      text-decoration: none
      position: relative
      display: flex
      padding: 18px 32px 20px 32px
      align-items: center
      gap: 33px
      text-transform: uppercase
      color: var(--txtDark)
      background: linear-gradient(90deg, #DCA8BF 0%, #6B85B6 25%, #6B85B6 100%)
      background-size: 400% 400%
      animation: notHover 600ms ease-in-out
      background-position: 0% 0%
      border-left: 2px solid var(--milkyBG, #E7E9E8)
      fill: var(--txtDark)
      stroke: var(--txtDark)
      .Links 
        display: none
      .icon
        position: relative
        z-index: 10
        width: 36px
        height: 42px
        fill: inherit
        stroke: inherit
      .text  
        position: relative
        z-index: 10
        color: inherit
      .pointer 
        z-index: 11
        position: absolute
        top: 0
        left: 0
        width: 100% 
        height: 100% 
        cursor: pointer
    .left:hover
      animation: hover 600ms ease-in-out
      background-position: 100% 0%
      color: var(--milkyBG)
      fill: var(--milkyBG)
      stroke: var(--milkyBG)
    .noise
      z-index: 8
      position: absolute
      top: 0
      left: 0
      width: 100% 
      height: 100%
      background: url("../assets/noise.png")
      mix-blend-mode: overlay
@media (max-width: 480px) 
  .header
    background: var(--txtDark) url('../assets/noise.png')
    z-index: 200
    
    position: absolute
    display: flex 
    padding: 0 8px
    box-sizing: border-box
    flex-direction: column
    // justify-content: flex-end
    color: var(--milkyBG)
    text-align: center
    width: 100%
    padding-bottom: 32px
    text-transform: uppercase
    .menu
      width: 100%
      top: 0
      margin-bottom: 100px
      position: relative
      display: flex
      justify-content: space-between
      align-items: center
      .logo 
        margin: 36px 0 0 24px
        width: 48px
      .menuClose
        position: absolute
        opacity: 1
        width: 40px
        margin: 32px 8px 0 0
        right: 0
    .right  
      position: relative
      display: flex
      flex-direction: column
      gap: 8px
      .btn
        position: relative
        font-size: 24px
        padding: 6px 10px
        .pointer
          position: absolute
          top: 0
          left: 0
          width: 100% 
          height: 100%
    .left
      display: flex 
      flex-direction: column
      margin-top: 71px
      .text
        position: relative
        color: var(--txtDark)
        font-size: 14px
        text-decoration: none
        background: var(--milkyBG)
        text-transform: uppercase
        padding: 12px 0
        border-radius: 10px
        .pointer2
          position: absolute
          width: 100%
          height: 100%
          top: 0
          left: 0
      .icon
        display: none
      .Links
        margin-top: 53px
        position: relative
        display: flex
        justify-content: center
        align-items: center
        gap: 8px
        a 
          position: relative
          gap: 0
          .iconLink
            width: 26px
          .iconStar
            position: absolute
            width: 8px
  .open
    animation: headerOpen 300ms ease-in-out
    
    height: 616px
    opacity: 1
  .close
    animation: headerClose 300ms ease-in-out
    height: 616px
    pointer-events: none
    opacity: 0

@keyframes headerOpen
  0% 
    // height: 0
    opacity: 0
  100%
    // height: 616px
    opacity: 1

@keyframes headerClose
  0% 
    height: 616px
    opacity: 1
  100%
    height: 0
    opacity: 0


</style>

<style>
@keyframes hover {
  0% {
        background-position: 0% 0%;
        color: var(--txtDark);
        fill: var(--txtDark);
        stroke: var(--txtDark);
    }
    100% {
        background-position: 100% 0%;
        color: var(--milkyBG);
        fill: var(--milkyBG);
        stroke: var(--milkyBG);
    }
}
@keyframes notHover {
  0% {
        background-position: 100% 0%;
        color: var(--milkyBG);
        fill: var(--milkyBG);
        stroke: var(--milkyBG);
    }
    100% {
        background-position: 0% 0%;
        color: var(--txtDark);
        fill: var(--txtDark);
        stroke: var(--txtDark);
    }
}
@keyframes btnHover {
    0%{
        background: var(--txtDark) url('../assets/noise.png');
    }
    100%{
        background: var(--accentBlue) url('../assets/noise.png');
    }
}
@keyframes btnNotHover {
    0%{
      background: var(--accentBlue) url('../assets/noise.png');
          
    }
    100%{
      background: var(--txtDark) url('../assets/noise.png');
    }
}
</style>

<script>

export default {
  name: 'headerPage',
  data(){
    return{
      blocks: [
        {name: 'о нас', id: 'about'},
        {name: 'услуги', id: 'services'},
        {name: 'кейсы', id: 'cases'},
        {name: 'команда', id: 'team'},
        {name: 'контакты', id: 'contacts'},
      ],
      menuReady: true,
    }
  },
  methods: {
    menuOpen(){
      if (this.menuReady===true){
        this.$store.commit('headerMobile')
      }
    }
  },
  computed: {
    headerActive(){
      return this.$store.state.headerActive
    }
  },
}
</script>