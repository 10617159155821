import Vue from 'vue'
import VueRouter from 'vue-router'
import errorBlock from '@/components/404Block.vue'
import mainPage from '@/components/mainPage.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', component: mainPage},
  {path: '/404', component: errorBlock},
  {path: '*', redirect: '/404'},
  // {path: ':any*/*', redirect: '/404'}
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
      if (to.hash) {
        return { selector: to.hash };
      }
    return { x: 0, y: 0 }
    },
})

export default router
