<script setup>
import preloaderBlock from './preloaderBlock.vue';
</script>

<template>
    <div class="block404">
        <div class="txt"><div class="number">404</div><div class="error sbf">error</div><div class="please bf">извините, нам не удалось найти эту страницу</div></div>
        <router-link to="/" class="btn"><div class="btnText sbf">перейти на главную</div></router-link>
        <preloaderBlock v-if="isLoading"></preloaderBlock>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 480px) 
    .block404
        position: relative
        box-sizing: border-box
        width: 100%
        height: 100vh
        background: var(--txtDark) url('../assets/noise.png')
        color: var(--milkyBG)
        display: flex
        padding-top: 13px
        align-items: center
        flex-direction: column
        gap: 100px
        text-transform: uppercase
        .txt    
            position: inherit
            height: 660px
            display: flex
            justify-content: center
            .number
                font-family: Jost
                font-size: 550px
                font-style: normal
                font-weight: 200
                line-height: 120%
                text-transform: uppercase
            .error
                position: absolute
                color: var(--milkyBG)
                top: 330px
            .please
                position: absolute
                bottom: 29px
                color: var(--milkyBG)
        .btn
            position: relative
            padding: 26px 40px
            border: 1px solid var(--milkyBG)
            text-decoration: none
            animation: btnNotHover 300ms ease-in-out
            background: var(--txtDark) url('../assets/noise.png')
            .btnText
                color: var(--milkyBG)
        .btn:hover
            animation: btnHover 300ms ease-in-out
            background: var(--accentBlue) url('../assets/noise.png')

    @keyframes btnHover
        0%
            background: var(--txtDark) url('../assets/noise.png')
        100% 
            background: var(--accentBlue) url('../assets/noise.png')
    
    @keyframes btnNotHover
        0%
            background: var(--accentBlue) url('../assets/noise.png')
        100% 
            background: var(--txtDark) url('../assets/noise.png')

@media (max-width: 480px) 
    .block404
        position: relative
        box-sizing: border-box
        width: 100%
        height: 100vh
        background: var(--txtDark) url('../assets/noise.png')
        color: var(--milkyBG)
        display: flex
        padding-top: 203px
        align-items: center
        flex-direction: column
        gap: 121px
        text-transform: uppercase
        .txt    
            position: inherit
            display: flex
            flex-direction: column
            align-items: center
            .number
                position: relative
                color: var(--backroundMilky, #E7E9E8)
                font-family: Jost
                font-size: 180px
                font-style: normal
                font-weight: 200
                line-height: 120%
            .error
                position: absolute
                color: var(--milkyBG)
                top: 98px
                font-size: 16px
                font-weight: 500
            .please
                position: relative
                color: var(--milkyBG)
                max-width: 253px   
                font-size: 16px
                font-style: normal
                font-weight: 300
                line-height: 120%
        .btn
            position: relative
            padding: 17px 22px
            border: 1px solid var(--milkyBG)
            .btnText
                color: var(--milkyBG)
                text-decoration: none

</style>

<script>
export default {
    data(){
        return{
            isLoading: false
        }
    },
    created(){
        this.setupRoutes()
    },
    methods: {
        setupRoutes(){
            this.$router.beforeEach((to,from,next) => {
                this.isLoading = true
                next()
            })
            this.$router.afterEach(() => {
                this.isLoading = false
            });
        }
    }
}
</script>