<template>
    <div class="cases">
        <div class="upper">
            <div class="titleh1">
                Кейсы
            </div>
            <div class="arrows">
                <div class="left">
                    <img src="../assets/icons/arrow.svg" alt="" class="leftArrow">
                    <img src="../assets/icons/arrowHover.svg" alt="" class="leftHover" @click="prevBlock">
                </div>
                <div class="right">
                    <img src="../assets/icons/arrow.svg" alt="" class="rightArrow">
                    <img src="../assets/icons/arrowHover.svg" alt="" class="rightHover" @click="nextBlock">
                </div>

            </div>
        </div>
        <div class="content" :class="{nextAnim: nxt===true, prevAnim: pr===true}" @wheel.prevent="onWheel"
        :style="{'--main-right-prev': `${offset-646}px`,'--main-left-prev': `${offset+646}px`,'--main-right-next': `${-offset}px`,
        '--main-right-prev-mob': `${offsetMob-256}px`,'--main-left-prev-mob': `${offsetMob+256}px`,'--main-right-next-mob': `${-offsetMob}px`}"
        @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
            <div class="images" v-for="(item,index) in cases" 
            :key="index" 
            :class="{prev: index<currentBlock, show: index>=currentBlock}">
                <img :src="item.src" alt="" class="background">
                
                <div class="subtitle bf">
                    {{ item.txt }}
                </div>
                <div class="hiddenText">
                    <div class="cardText sbf" >Смотреть проект <img src="../assets/icons/arrowItalic.svg" alt="" v-if="index%2===0"> <img src="../assets/icons/arrowItalicWhite.svg" alt="" v-if="index%2!=0"></div>
                </div>
            </div>
        </div>
        <div class="noise"></div>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 480px)   
    .cases 
        position: relative
        padding: 132px 0 154px 144px
        background: var(--milkyBG)
        overflow: hidden
        
        .upper
            display: flex
            justify-content: space-between
            align-items: center
            text-transform: uppercase
            margin-bottom: 49px
            .arrows
                margin-right: 104px
                display: flex
                gap: 48px
                .left   
                    position: relative
                    width: 87px
                    height: 87px
                    cursor: pointer
                    .leftArrow
                        width: 87px
                        height: 87px
                        z-index: 10
                        position: absolute
                        transform: rotate(180deg)
                    .leftHover
                        animation: hideLink 300ms ease-in-out
                        z-index: 10
                        position: absolute
                        top: 0
                        width: 87px
                        height: 87px
                        transform: rotate(180deg)
                        opacity: 0
                    .leftHover:hover
                        animation: showLink 300ms ease-in-out
                        opacity: 1
                .right  
                    position: relative 
                    width: 87px
                    height: 87px
                    cursor: pointer
                    .rightArrow
                        z-index: 10
                        width: 87px
                        height: 87px
                        position: absolute
                    .rightHover
                        animation: hideLink 300ms ease-in-out   
                        z-index: 10
                        width: 87px
                        height: 87px
                        position: absolute
                        top: 0
                        opacity: 0
                    .rightHover:hover
                        animation: showLink 300ms ease-in-out
                        opacity: 1
        
                   
        .content
            position: relative
            z-index: 3
            display: flex
            transition: transform 0.3s ease-in-out
            gap: 48px
            // right: var(--main-right-next)
            
            flex-wrap: nowrap
            width: 10000px
            .prev
                animation: hideLink 300ms ease-in-out
                opacity: 0
            .show
                animation: showLink 300ms ease-in-out
            .images
                position: relative
                height: 598px
                width: 598px
                display: flex
                justify-content: center
                align-items: center
                padding: 40px 63px 80px 63px
                border-radius: 10px
                border: 1px solid var(--txtDark)
                box-sizing: border-box
                // overflow: hidden
                .background 
                    position: relative
                    // top: 0
                    // left: 0
                    // right: 0
                    // bottom: 0
                    object-fit: cover
                    width: 100%
                .subtitle
                    position: absolute
                    display: flex
                    border-radius: 10px
                    top: -16px
                    right: -16px
                    padding: 10px 20px
                
                .hiddenText
                    animation: hideLink 300ms ease-in-out
                    z-index: 10
                    position: absolute
                    top: 0
                    left: 0
                    width: 100%
                    height: 100%
                    opacity: 0
                    cursor: pointer
                    .cardText
                        position: absolute
                        bottom: 22px
                        right: 40px
                        align-items: center
                        display: flex
                        gap: 8px
                        text-transform: uppercase                    
                .hiddenText:hover
                    animation: showLink 300ms ease-in-out
                    opacity: 1
            .images:nth-child(odd) .hiddenText .cardText
                color: var(--txtDark)
            .images:nth-child(even) .hiddenText .cardText
                color: var(--milkyBG)
            .images:nth-child(odd) .subtitle
                background: var(--accentPink) 
            .images:nth-child(even) .subtitle
                background: var(--accentBlue)
            .images:nth-child(odd)
                background: var(--milkyBG)
            .images:nth-child(even)
                background: var(--txtDark)
        .nextAnim
            // animation: transfRight 300ms ease-in-out
            transform: translateX(var(--main-right-next))
        .prevAnim
            // animation: transfLeft 300ms ease-in-out  
            transform: translateX(var(--main-right-next))   
        .noise
            position: absolute
            z-index: 1
            top: 0
            left: 0
            width: 100%
            height: 100%
            background: url('/src/assets/noise.png')
            mix-blend-mode: overlay

    @keyframes showLink 
        0%
            opacity: 0
        
        100%
            opacity: 1
        
    @keyframes hideLink 
        0%
            opacity: 1
        
        100%
            opacity: 0
    @keyframes transfRight
        0%
            right: var(--main-right-prev)
        100%
            right: var(--main-right-next)
    @keyframes transfLeft
        0%
            right: var(--main-left-prev)
        100%
            right: var(--main-right-next)

@media (max-width: 480px) 
    .cases
        position: relative
        padding: 56px 0 56px 16px
        overflow: hidden
        background: var(--milkyBG)
        .upper 
            display: flex
            position: relative
            justify-content: space-between
            align-items: center
            text-transform: uppercase
            z-index: 3
            margin-bottom: 24px
            padding-right: 16px
            .arrows
                display: none
        .content
            position: relative
            z-index: 3
            display: flex
            // right: var(--main-right-next-mob)
            transition: transform 0.3s ease-in-out
            gap: 24px
            flex-wrap: nowrap
            width: 1920px
            .prev
                animation: hideLink 300ms ease-in-out
                opacity: 0
            .show
                animation: showLink 300ms ease-in-out
            .images
                position: relative
                height: 232px
                width: 232px
                border-radius: 10px 
                display: flex
                justify-content: center 
                padding-top: 14px
                box-sizing: border-box
                border: 1px solid var(--txtDark)
                .background
                    background-size: cover
                    height: 184px
                    width: 184px
                .subtitle
                    position: absolute
                    display: flex
                    border-radius: 10px
                    top: -16px
                    right: -16px
                    padding: 5px 13px
                    font-size: 24px
                    text-transform: uppercase
                .hiddenText
                    z-index: 10
                    position: absolute
                    top: 0
                    left: 0
                    width: 100%
                    height: 100%
                    .cardText
                        position: absolute
                        bottom: 13px
                        right: 16px
                        align-items: center
                        display: flex
                        gap: 4px
                        text-transform: uppercase
                        font-size: 14px
                        line-height: 120%
                        font-weight: 500
                        img
                            width: 18px
                            height: 18px
                    .hiddenDark
                        color: var(--txtDark)
                    .hiddenLight
                        color: var(--milkyBG)
            .images:nth-child(odd) .hiddenText .cardText
                color: var(--txtDark)
            .images:nth-child(even) .hiddenText .cardText
                color: var(--milkyBG)
            .images:nth-child(odd) .subtitle
                background: var(--accentPink) 
            .images:nth-child(even) .subtitle
                background: var(--accentBlue)
            .images:nth-child(odd) 
                background: var(--milkyBG)
            .images:nth-child(even)
                background: var(--txtDark)
        .nextAnim
            // animation: transfRight 300ms ease-in-out
            transform: translateX(var(--main-right-next-mob))
        .prevAnim
            // animation: transfLeft 300ms ease-in-out  
            transform: translateX(var(--main-right-next-mob))  
        .noise
            position: absolute
            z-index: 1
            top: 0
            left: 0
            width: 100%
            height: 100%
            background: url('/src/assets/noise.png')
            mix-blend-mode: overlay

    @keyframes showLink 
        0%
            opacity: 0
        
        100%
            opacity: 1
        
    @keyframes hideLink 
        0%
            opacity: 1
        
        100%
            opacity: 0

    @keyframes transfRight
        0%
            right: var(--main-right-prev-mob)
        100%
            right: var(--main-right-next-mob)
    @keyframes transfLeft
        0%
            right: var(--main-left-prev-mob)
        100%
            right: var(--main-right-next-mob)
</style>

<script>
// import axios from 'axios';
export default {
    data(){
        return {
            cases: [
                {src: './caseImages/Dizzy.png', txt: 'SMM'},
                {src: './caseImages/Korobok(1).png', txt: 'SMM'},
                {src: './caseImages/BurgerLub.png', txt: 'SMM'},
                {src: './caseImages/Luna.png', txt: 'SMM'},
                {src: './caseImages/Shake.png', txt: 'SMM'},
                {src: './caseImages/Pelmeni.png', txt: 'SMM'},
                {src: './caseImages/kSebeNaTi.png', txt: 'ПОДКАСТ'},
            ],
            currentBlock: 0,
            offset: 0,
            offsetMob: 0,
            nxt: false,
            pr: false,
            btnReady: true,
            startX: 0,
            endX: 0,
        }
    },
    methods: {
        nextBlock(){
            if(this.currentBlock < this.cases.length-1 && this.btnReady === true){
                this.currentBlock+=1
                this.offset += 646
                this.offsetMob += 256
                this.nxt = true
                this.btnReady=false
                setTimeout(()=>{
                    this.btnReady=true
                }, 300)
                
            } 
        },
        prevBlock(){
            if(this.currentBlock > 0 && this.btnReady === true){
                this.currentBlock-=1
                this.offset -= 646
                this.offsetMob -= 256
                this.pr = true 
                this.btnReady=false
                setTimeout(()=>{
                    this.pr=false
                    this.btnReady=true
                }, 300)
            } 
        },
        onTouchStart(e) {
            this.startX = e.touches[0].clientX;
        },
        onTouchMove(e) {
            this.endX = e.touches[0].clientX;
        },
        onTouchEnd() {
            const diff = this.startX - this.endX;
            if (Math.abs(diff) > 50 && this.endX!=0 && this.btnReady === true) {
                if (diff > 0) {
                this.nextBlock();
                } else {
                this.prevBlock();
                }
            }
            this.startX = 0
            this.endX = 0
        },
        onWheel(event) {
            setTimeout(()=>{
                if(event.deltaY>0){
                    this.nextBlock()
                }else{
                    this.prevBlock()
                }
            },300)    
            event.preventDefault();
        }
    },
    mounted(){
        // let protocol = this.$store.state.protocol
        // let host = this.$store.state.host
        // axios.get(`${protocol}://${host}/api/cases`)
        // .then(response => {
        //     console.log(response)
        // })
    }
}
</script>