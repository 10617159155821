<template>
    <div class="footerMain">
        <div class="footerlinks sbf">
            <a href="https://docs.google.com/document/d/1VwNwY8wEtiiQsY8XSRZF8Ff01KJPoPE_y-mrKyaUybQ/edit" target="_blank" class="politics">Политика конфиденциальности</a>
            <a href="http://asapeducation.ru/" target="_blank" class="createdBy">Разработано в Asap Edu.</a>
        </div>
        <div class="footerLogo">
            <img src="../assets/logo.svg" alt="Widen">
        </div>
        <div class="footerLeft">
            <div class="footerIcons">
                <a :href="linkTG + contacts[1].value" target="_blank"><img src="../assets/icons/Telegram.svg" alt="" class="icon"></a>
                <a :href="contacts[2].value" target="_blank"><img src="../assets/icons/Instagram.svg" alt="" class="icon"></a>
                <img src="../assets/icons/iconStar.svg" class="iconStar">
            </div>
            <p class="sbrf">{{ contacts[0].value }}</p>
        </div>
        
        <div class="warn sbf">*Instagram — проект Meta Platforms Inc., деятельность которой запрещена на территории РФ</div>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 480px)
    .footerMain
        position: relative
        height: 373px
        box-sizing: border-box
        padding: 115px 95px 114px 88px
        background: var(--txtDark) url(../assets/noise.png)
        mix-blend-mode: overlay
        display: flex
        justify-content: space-between
        color: var(--milkyBG)
        align-items: center
        .footerlinks
            position: relative
            z-index: 100
            display: flex
            flex-direction: column
            gap: 11px
            .politics
                cursor: pointer
                text-decoration: none
                color: var(--milkyBG)
            .createdBy
                cursor: pointer
                text-decoration: none
                color: var(--milkyBG)
        .footerLogo
            position: absolute
            width: 100vw
            left: 0
            display: flex
            justify-content: center
        .footerLeft
            .sbrf 
                color: var(--milkyBG)
                margin-top: 12px
            .footerIcons
                position: relative
                z-index: 100
                display: flex
                justify-content: flex-end
                gap: 16px
                .icon 
                    width: 64px
                .iconStar
                    width: 19px 
                    position: absolute
                    right: -15px
        .warn 
            position: absolute
            bottom: 23px
            right: 88px
            max-width: 576px
            text-align: right
            color: var(--milkyBG)
@media (max-width: 480px)
    .footerMain
        position: relative
        width: 100%
        box-sizing: border-box
        padding: 32px 62px 36px 62px
        background: var(--txtDark) url(../assets/noise.png)
        mix-blend-mode: overlay
        display: flex
        flex-direction: column-reverse
        color: var(--milkyBG)
        align-items: center
        .footerlinks
            position: relative
            z-index: 100
            display: flex
            flex-direction: column
            gap: 11px
            text-align: center
            font-size: 14px
            .politics
                color: var(--milkyBG)
                text-decoration: none
            .createdBy
                color: var(--milkyBG)
                text-decoration: none
        .footerLogo
            order: 4
            margin-bottom: 18px
            img
                width: 50px
                height: 30px
        .footerLeft
            display: flex
            flex-direction: column-reverse
            gap: 33px
            order: 3
            margin-bottom: 5px
            .sbrf
                order: 1
                font-size: 14px
                text-transform: none
                margin: 0
            .footerIcons
                position: relative
                display: flex
                justify-content: center
                gap: 8px
                .icon
                    width: 40px
                    height: 40px
                .iconStar
                    position: absolute
                    top: 0
                    right: 20px
                    width: 8px
                    height: 8px
        .warn
            order: 2
            text-align: center
            margin-bottom: 44px
        

</style>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            protocol: this.$store.state.protocol,
            host: this.$store.state.host,
            linkTG: 'https://t.me/',
            contacts: [{type: 'email', value: ''},{type: 'telegram', value: ''},{type: 'instagram', value: ''}]
        }
    },
    mounted(){
        axios.get(`${this.protocol}://${this.host}/api/contacts`)
        .then(response =>{
            for(const elem of response.data.data){
                for(const contact of this.contacts){
                    if (elem.type===contact.type){
                        contact.value=elem.value
                    }
                }
            }
            this.contacts[1].value = this.contacts[1].value.slice(1)
        })
        
    }
}
</script>