<template>
    <div class="services">
        <div class="services__content">
            <div class="services__content services__content--title titleh1">Услуги</div>
            <div class="services__content services__content--blocks"
            v-for="item in services" :key="item.id"
            @click="openInfo(item.id)">
                <div class="preview" :class="{notSelected: currentService!=item.id}">
                    <div class="number sbrf">
                        ({{ item.id }})
                    </div>
                    <div class="blockTitle titleh2">
                        {{ item.name }}
                    </div>
                    <img src="../assets/icons/iconOpen.svg" alt="Open" class="iconOpen" :class="{open: currentService===item.id, notOpen: currentService!=item.id}">
                </div>
                <div class="serviceInfo"  :class="{hidden: currentService!=item.id, open: currentService===item.id}" :style="{'--flex-height': `${item.types.length*34+(item.types.length-1)*16+8+16}px`}">
                    <div class="types" >
                        <div class="type bf b2f"  v-for="type in item.types" :key="type">
                            <div class="name">{{ type }}</div> 
                            <img src="../assets/point.svg" alt="">
                        </div>
                    </div>
                    <div class="lines">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="underLine"></div>
                <div class="blockNoise"></div>
            </div>
        </div>
        <div class="services__noise"></div>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 480px)     
    .services
        position: relative
        background-color: var(--milkyBG)
        &__content
            position: relative
            z-index: 2
            padding: 144px 100px 183px 144px
            &--title 
                padding: 0
                margin-bottom: 56px
                text-transform: uppercase
            &--blocks
                background: var(--milkyBG)  
                padding: 0
                position: relative
                .preview
                    position: relative
                    z-index: 4
                    display: flex
                    justify-content: space-between
                    align-items: center
                    padding: 31px 32px 32px 81px
                    cursor: pointer
                    background: linear-gradient(90deg, var(--milkyBG) 0%, var(--milkyBG) 25%, #DCA8BF 85%, #6B85B6 100%)
                    background-size: 500%
                    background-position: 0
                    animation: notHover 300ms ease-in-out
                    .blockTitle
                        text-transform: uppercase
                    .number
                        display: flex
                        align-items: center
                    .open
                        animation: iconAnim 300ms ease-in-out
                        width: 56px
                        height: 56px
                        transform: rotate(90deg)
                    .notOpen
                        animation: iconAnimClose 300ms ease-in-out
                        width: 56px
                        height: 56px
                        transform: rotate(0deg)
                .notSelected:hover 
                    animation: prevAnim 300ms ease-in-out
                    background-position: 100%
                .underLine
                    position: relative
                    z-index: 4
                    width: 100%
                    height: 1px
                    background: #383838
                .number
                    padding: 0
                    position: absolute
                    left: 8px
                    bottom: 64px
                .blockNoise
                    opacity: 1
                    position: absolute
                    top: 0
                    left: 0
                    z-index: 0
                    width: 100%
                    height: 100%
                    background-image: url('../assets/noise.png')
                    filter: contrast(7%) brightness(170%)
                .serviceInfo
                    background: var(--milkyBG)
                    position: relative
                    z-index: 4
                    display: flex
                    flex-direction: column
                    gap: 24px
                    text-transform: uppercase
                    justify-content: space-between
                    .types
                        display: flex
                        flex-direction: row
                        justify-content: space-around
                        .type 
                            max-width: 534px
                            display: flex
                            flex-direction: column
                            justify-content: flex-end
                            align-items: center
                            text-align: center
                            img 
                                position: absolute
                                top: 108px
                    .lines
                        display: flex
                        justify-content: space-between
                        animation: show 1500ms ease-in-out
                        .line
                            position: relative
                            z-index: 4
                            width: 1px
                            height: 70px
                            background: #383838
        &__noise
            position: absolute
            top: 0
            left: 0
            z-index: 10
            width: 100%
            height: 100%
            background-image: url('../assets/noise.png')
            mix-blend-mode: overlay
            pointer-events: none
@media (max-width: 480px)
    .services
        position: relative
        background-color: var(--milkyBG)
        &__content
            position: relative
            z-index: 15
            padding: 56px 16px 30px 16px
            &--title 
                padding: 0
                margin-bottom: 32px
                text-transform: uppercase
            &--blocks
                background: var(--milkyBG)  
                padding: 0
                position: relative
                .preview
                    position: inherit
                    z-index: 4
                    display: flex
                    justify-content: space-between
                    align-items: center
                    padding: 15px 18px 11px 33px
                    cursor: pointer
                    .number
                        padding: 0
                        z-index: 20
                        position: absolute
                        left: 8px
                        top: 19px
                    .blockTitle
                        z-index: 20
                        position: relative
                    .open   
                        animation: iconAnim 300ms ease-in-out
                        width: 20px
                        height: 20px
                        transform: rotate(90deg)
                    .notOpen
                        animation: iconAnimClose 300ms ease-in-out
                        width: 20px
                        height: 20px
                        transform: rotate(0deg)
                .serviceInfo
                    background: var(--milkyBG)
                    position: relative
                    z-index: 4
                    .types
                        position: relative
                        display: flex
                        box-sizing: border-box
                        width: 100% 
                        height: 100%
                        flex-direction: column
                        padding-top: 8px
                        padding-bottom: 16px
                        gap: 16px
                        .type
                            position: inherit
                            width: 100% 
                            height: 100%
                            word-wrap: break-word
                            img 
                                transform: rotate(90deg)
                                width: 24px
                                height: 24px
                                position: absolute
                                top: -4px
                                left: 0
                                z-index: 20
                            .name
                                position: relative
                                word-wrap: break-word
                                width: calc( 100% - 24px )
                                z-index: 20
                                left: 34px
                                text-align: left
                .underLine
                    position: relative
                    z-index: 4
                    width: 100%
                    height: 0.5px
                    background: #383838
                .blockNoise
                    position: absolute
                    top: 0
                    left: 0
                    z-index: 10
                    width: 100%
                    height: 100%
                    background-image: url('../assets/noise.png')
                    mix-blend-mode: overlay
                    pointer-events: none
        &__noise
            position: absolute
            top: 0
            left: 0
            z-index: 10
            width: 100%
            height: 100%
            background-image: url('../assets/noise.png')
            mix-blend-mode: overlay
            pointer-events: none



                
    

</style>

<script>
import axios from 'axios';
export default {
    name: 'servicesBlock',
    data(){
        return{
            services: [],
            currentService: '',
            ready: true
        }
    },
    methods: {
        openInfo(id){
            if(this.ready===true){
                this.ready=false
                setTimeout(()=>{
                    if(this.currentService === id){
                        this.currentService=''
                    }else{
                        this.currentService=id
                    }
                    this.ready=true
                }, 300)
            }
        },
        
    },
    mounted(){
        let protocol = this.$store.state.protocol
        let host = this.$store.state.host
        axios.get(`${protocol}://${host}/api/categories`)
        .then(response=>{
        
        this.services = response.data.data
        for(const elem of this.services){
            elem.types=[]
        }
        axios.get(`${protocol}://${host}/api/services`)
        .then(response=>{
            for(const service of this.services){
            for(const data of response.data.data){
                if(data.category_id === service.id){
                service.types.push(data.name)
                }
            }
            }
        })
        })
    }
}
</script>

<style>
@media (min-width: 480px) {
    .open{
        animation: openInfo 300ms ease-in-out;
        height: 234px;
        
    }
    .hidden{
        animation: closeInfo 300ms ease-in-out;
        height: 0;
        opacity: 0;
    }
    @keyframes closeInfo{
        0%{
            height: 234px;
            opacity: 1;
        }
        100%{
            height: 0px;
            opacity: 0;
        }
    }
    @keyframes openInfo {
        0%{
            height: 0px;
            opacity: 0;
        }
        100%{
            height: 234px;
            opacity: 1;
        }
    }
    @keyframes show{
        0%{
            opacity: 0;
            height: 0;
        }
        100%{
            height: 70px;
            opacity: 1;
        }
    }
    @keyframes iconAnim{
        0%{
            width: 56px;
            height: 56px;
            transform: rotate(0deg);
        }
        100%{
            width: 56px;
            height: 56px;
            transform: rotate(90deg);
        }
    }
    @keyframes prevAnim {
        0% {
            background-position: 0;
            
        }
        100% {
            background-position: 100%;
        }
    }
    @keyframes notHover{
        0% {
            background-position: 100%;
            
        }
        100% {
            background-position: 0;
        }
    }
    @keyframes iconAnimClose {
        0%{
            width: 56px;
            height: 56px;
            transform: rotate(90deg);
        }
        100%{
            width: 56px;
            height: 56px;
            transform: rotate(0deg);
        }
    }
}


@media (max-width: 480px) {
    .open{
        animation: openInfo 300ms ease-in-out;
        height: var(--flex-height);       
    }
    .hidden{
        animation: closeInfo 300ms ease-in-out;
        height: 0;
        opacity: 0;
    }
    @keyframes iconAnim{
        0%{
            width: 20px;
            height: 20px;
            transform: rotate(0deg);
        }
        100%{
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
        }
    }
    @keyframes iconAnimClose {
        0%{
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
        }
        100%{
            width: 20px;
            height: 20px;
            transform: rotate(0deg);
        }
    }
    @keyframes closeInfo{
        0%{
            height: var(--flex-height);
            opacity: 1;
        }
        100%{
            height: 0px;
            opacity: 0;
        }
    }
    @keyframes openInfo {
        0%{
            height: 0px;
            opacity: 0;
        }
        100%{
            height: var(--flex-height);
            opacity: 1;
        }
    }
}
</style>